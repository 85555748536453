<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('other_payments')"
                        :isNewButton="checkPermission('otherpayment_store')"
                        @new-button-click="createFormShow"
                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('other_payments')"
                              :isNewButton="checkPermission('otherpayment_store')"
                              @new-button-click="createFormShow"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              @filter="$refs.datatable.getRows()"
                              @filterClear="$refs.datatable.filterClear()"
                              :exportExcel="true"
                              @exportExcel="$refs.datatable.excel()">
                <b-row>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input v-model="datatable.queryParams.filter.number">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('national_id')">
                            <b-form-input v-model="datatable.queryParams.filter.customer_national_id">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.customer_name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input v-model="datatable.queryParams.filter.customer_surname">
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('price_type')">
                            <parameter-selectbox
                                code="other_payment_products"
                                v-model="datatable.queryParams.filter.product_code"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('start_date')">
                            <b-form-input type="date" v-model="datatable.queryParams.filter.start_date">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('end_date')">
                            <b-form-input type="date" v-model="datatable.queryParams.filter.end_date">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" xl="3">
                        <b-form-group :label="$t('is_paid')">
                            <true-false-selectbox v-model="datatable.queryParams.filter.is_paid" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService">
            </datatable>
            <CommonModal id="otherPaymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('other_payments') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="otherPaymentForm">
                        <div class="row" v-if="otherPaymentForm">
                            <div class="col-12 col-sm-6 col-md-4">
                                <ValidationProvider name="name" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('name') }}</label>
                                        <b-form-input v-uppercase
                                                      v-model="otherPaymentForm.name"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <ValidationProvider name="surname" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('surname') }}</label>
                                        <b-form-input v-uppercase
                                                      v-model="otherPaymentForm.surname"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4">
                                <ValidationProvider name="national_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('national_id') }}</label>
                                        <b-form-input v-number
                                                      v-model="otherPaymentForm.national_id"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="period_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('period') }}</label>
                                        <payment-periods-selectbox
                                            :validate-error="errors[0]"
                                            :only-active="true"
                                            :only-yearly="true"
                                            v-model="otherPaymentForm.period_id"></payment-periods-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="product_code" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('price_type') }}</label>
                                        <parameter-selectbox
                                            code="other_payment_products"
                                            :validate-error="errors[0]"
                                            v-model="otherPaymentForm.product_code"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="amount" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                        <b-form-input
                                            type="number"
                                            step=".01"
                                            v-model="otherPaymentForm.amount"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="payment_date" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                        <b-form-input
                                            type="date"
                                            v-model="otherPaymentForm.payment_date"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                        <currency-selectbox
                                            :validateError="errors[0]"
                                            v-model="otherPaymentForm.currency">
                                        </currency-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="currency_rate" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                        <b-form-input

                                            type="number"
                                            step=".0001"
                                            v-model="otherPaymentForm.currency_rate"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]"
                                                        v-model="otherPaymentForm.bank_id" ref="otherPaymentBank">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="payment_type_id" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]"
                                                                 type="credit_card" :is_bank_required="true"
                                                                 :bank_id="otherPaymentForm.bank_id"
                                                                 v-model="otherPaymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="provision" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('provision') }}</label>
                                        <b-form-input
                                            type="number"
                                            v-model="otherPaymentForm.related_number"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="terminalOptions">
                                <ValidationProvider name="terminal" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('terminal')">
                                        <b-form-select :options="terminalOptions"
                                                       v-model="otherPaymentForm.account_number"></b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <ValidationProvider name="card_no" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('card_no')">
                                        <b-form-input
                                            autocomplete="false"
                                            v-mask="'####-****-****-####'"
                                            v-model="otherPaymentForm.related_info"
                                        ></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="explanation" rules=""
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                        <b-form-input
                                            v-model="otherPaymentForm.explanation"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveOtherPayment" variant="primary">{{
                                        $t('save')
                                    }}
                                </b-button>
                            </div>

                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import OtherPaymentService from "@/services/OtherPaymentService";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox";
import PaymentTypesSelectbox from "@/components/interactive-fields/PaymentTypesSelectbox";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import CurrencyRateService from "@/services/CurrencyRateService";
import BankSelectbox from "@/components/interactive-fields/BankSelectbox";
import PaymentService from "@/services/PaymentService";
import ReceiptService from "@/services/ReceiptService";

export default {
    components: {
        BankSelectbox,
        PaymentTypesSelectbox,
        CurrencySelectbox,
        MultiSelectbox,
        PaymentPeriodsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ParameterSelectbox,
        CommonModal,
        ValidationProvider,
        ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t("other_payments"),
        };
    },
    data() {
        return {
            otherPaymentForm: null,
            terminalOptions: [],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass:
                            "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "otherpayment_update",
                                callback: (row) => {
                                    this.showOtherPayment(row);
                                },
                            },
                            {
                                text: this.$t("print"),
                                class: "ri-printer-line align-middle top-minus-1 mr-3 text-muted",
                                permission: "otherpayment_show",
                                callback: (row) => {
                                    this.printReceipt(row.receipt_number)
                                },
                                show(row){
                                    if(row.receipt_number){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "otherpayment_delete",
                                callback: (row) => {
                                    this.deleteOtherPayment(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: 'number',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("national_id"),
                        field: 'national_id',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: 'surname',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("period"),
                        field: 'period',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("price_type"),
                        field: 'product_name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("payment_type"),
                        field: 'payment_type_name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("amount"),
                        field: 'amount',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("currency"),
                        field: 'currency',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("due_date"),
                        field: 'due_date',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase("payment_date"),
                        field: 'payment_date',
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        callService(config) {
            return OtherPaymentService.getAll(config)
        },

        createFormShow() {
            this.formId = null
            this.otherPaymentForm = {};
            this.$bvModal.show('otherPaymentModal');
        },
        getRows() {
            this.$refs.datatable.getRows();
        },
        setOtherPaymentTerminals(selected) {

            if (!selected || typeof this.$refs.otherPaymentBank == 'undefined') {
                return;
            }
            this.terminalOptions = [];
            let item = this.$refs.otherPaymentBank.getItem(selected);
            if (item.options.terminals) {
                this.terminalOptions = ['', ...item.options.terminals];
            }
        },
        async saveOtherPayment() {
            const isValid = await this.$refs.otherPaymentForm.validate();
            if (isValid) {
                if (this.otherPaymentForm.id > 0) {
                    OtherPaymentService.update(this.otherPaymentForm.id, this.otherPaymentForm)
                        .then(response => {
                            this.toast(response.data);
                            this.$bvModal.hide('otherPaymentModal');
                            this.showOtherPayments();
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.otherPaymentForm);
                        });
                } else {
                    OtherPaymentService.store(this.otherPaymentForm)
                        .then(response => {
                            this.printReceipt(response.data.data.receipt_number);
                            this.toast(response.data);
                            this.$bvModal.hide('otherPaymentModal');
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.otherPaymentForm);
                        });
                }
            }
        },
        showOtherPayment(row = {}) {
            this.$bvModal.show('otherPaymentModal');
            this.otherPaymentForm = row;
            this.terminalOptions = null;
            this.terminalOptions = ['', ...row.terminals];
        },
        deleteOtherPayment(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        OtherPaymentService.del(id)
                            .then(response => {
                                this.toast(response.data);
                                this.getRows();
                            })
                            .catch(e => {
                                this.showErrors(e, this.$refs.customerPriceForm);
                            })
                    }
                })
        },
        setCurrencyRate(obj) {
            var date = null;
            if (typeof obj.payment_date != 'undefined') {
                date = obj.payment_date;
            }
            if (typeof obj.refund_date != 'undefined') {
                date = obj.refund_date;
            }

            if (!obj || obj.currency == 'TRY' || !date) {
                return;
            }
            CurrencyRateService.getByDate(date, obj.currency)
                .then(response => {
                    if (response.data.data && response.data.data.banknote_selling) {
                        this.$set(obj, 'currency_rate', response.data.data.banknote_selling);
                    }
                });
        },
        printReceipt(number) {
            if (!number || number == '0') {
                return;
            }
            PaymentService.getReceipt(number)
                .then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']})
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
    },
    watch: {
        'otherPaymentForm.bank_id': function (selected) {
            this.setOtherPaymentTerminals(selected);
        },
        'otherPaymentForm.payment_date'(newVal) {
            this.setCurrencyRate(this.otherPaymentForm);
        },
        'otherPaymentForm.currency'(newVal) {
            this.setCurrencyRate(this.otherPaymentForm);
        },
    }
};
</script>

